import oucy from "@/util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store"
import QRCode from 'qrcodejs2'
import { schemeOrder, ticket, financial, balance } from "@/service"
export default {
    name: "payOrder",
    data() {
        return {
            count: 0,
            timer: null,
            showGetCode: true,
            showType: 'select', //pay  select
            pageWidth: oucy.pageWidth,
            checked: 1,
            radio: 1,
            num: 1,
            dialogVisible: '',
            dialogVisible222: '1',
            input: '',
            textarea: '',
            qrCode: null,
            payExpSeconds: null,

            tmpOrder: {},
            loginUser: {},
            ConfirmOrderScheme: null,
            codeTime: 0,
            mode: 0,
            payMode: 0,
            fee: 0,
            payFee: 0,
            showGetCode: true,
            phoneCode: null,
            cardLevelId: null,
            orderId: null,
            type: null,
            payObject: {},
            balance: 0,
        }
    },
    mounted() {
        this.orderId = this.$route.query.orderId
        this.payObject = localGet('payObject')
        if (!this.payObject) {
            this.$alert('没有可支付订单')
            return
        }

        this.fee = this.payObject.fee * 1
        this.type = this.payObject.type || 'reConfirmOrderScheme'

        this.eventBus.$off(this.$oucy.msgKeys.BALANCE_PAY_SUCCESS).$on(this.$oucy.msgKeys.BALANCE_PAY_SUCCESS, (res) => {
            this.finish()
        })
        this.eventBus.$off(this.$oucy.msgKeys.CARD_MEMBER_PAY_SUCCESS).$on(this.$oucy.msgKeys.CARD_MEMBER_PAY_SUCCESS, (res) => {
            this.finish()
        })
        this.eventBus.$off(this.$oucy.msgKeys.SPU_PAY_SUCCESS).$on(this.$oucy.msgKeys.SPU_PAY_SUCCESS, (res) => {
            this.finish()
        })

        this.queryUserBalance()
        this.loginUser = localGet(this.$oucy.userInfoKey)
    },
    methods: {
        setShowType(v) {
            this.showType = v
            if ('pay' == v && this.mode != 4) {
                this.createOrder()
            }
        },
        queryUserBalance() {
            financial.queryUserBalance().then(res => {
                this.balance = res || 0
            })
        },
        setMode(v) {
            this.payMode = v
            this.mode = v
            // this.createOrder()
        },
        createOrder() {
            // type 接口
            if (this.mode == 4) {
                if (!this.phoneCode) {
                    this.$message('请输入短信验证码')
                    return
                }
                this.payObject.code = this.phoneCode
            }
            this.payObject.uuid = this.$oucy.getUUID()
            this.payObject.schemeOrderPayChennel = this.payMode
            this.payObject.payMode = this.payMode
            this.payObject.payPlatform = 'browser'
            // 再次订单
            if (this.type == 'reConfirmOrderScheme') {
                this.reConfirmOrderScheme()
            } else
            if (this.type == 'createMemberCardOrder') {
                // 会员卡
                this.createMemberCardOrder()
            } else
            if (this.type == 'confirmOrderScheme') {
                // 订单
                this.confirmOrderScheme()
            } else
            if (this.type == 'createRechargeOrder') {
                // 充值
                this.createRechargeOrder()
            } else
            if (this.type == 'enterpriseUpgrade') {
                // 店铺
                this.enterpriseUpgrade()
            }
        },
        finish() {
            oucy.replace('/payCenter/finish')
            localDel('payObject')
        },
        handleChange(value) {
            console.log(value);
        },
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        creatQrCode(text) {
            if (this.mode == 4) {
                this.finish()
            } else {

                const me = this;
                if (this.qrCode == null) {
                    this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
                        text: text,
                        width: 146,
                        height: 146,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                } else {
                    this.qrCode.makeCode(text)
                }
            }
        },
        updateTime(v) {
            let payExpSeconds = v.hour * 60 * 60 + v.minute * 60 + v.sec
            // this.tmpOrder.order.payExpSeconds = payExpSeconds
            // payExpSeconds % 2 == 0 && localSet('tmpOrder', this.tmpOrder)
        },
        stopTime(v) {
            // this.tmpOrder.order.payExpSeconds = 0
            // localSet('tmpOrder', this.tmpOrder)
        },
        initPay(res) {
            this.payFee = res.thirdPartyPayFee || res.fee
            console.log('this.payFee',this.payFee)
            if(!this.payFee){
                this.$oucy.confirm('0元(面议商品)无法生成订单，请联系客服').then(res=>{
                    this.$oucy.replace('/')
                }).catch(err=>{
                    this.$oucy.replace('/')
                })
            }else{
                this.payObject.orderId = res.orderId
                this.qrCode && this.qrCode.clear();
                this.creatQrCode(res.qrCode)
                this.payExpSeconds = res.payExpSeconds
                setTimeout(() => {
                    this.$refs.countDown.myinit()
                })
                
            }
        },
        finishOrder() {},
        createMemberCardOrder() {
            financial.createMemberCardOrder(this.payObject).then(res => {
                // financial.createMemberCardOrder({ cardLevelId: this.cardLevelId, payMode: this.payMode, payPlatform: 'browser', uuid: oucy.getUUID() }).then(res => {
                this.initPay(res)
                console.log(res)
            })
        },
        confirmOrderScheme() {
            schemeOrder.confirmOrderScheme(this.payObject).then(res => {
                this.initPay(res)

            })
        },
        createRechargeOrder() {

            balance.createRechargeOrder(this.payObject).then(res => {
                this.initPay(res)
            })
        },
        enterpriseUpgrade() {
            this.payObject.uuid = this.$oucy.getUUID()

            financial.enterpriseUpgrade(this.payObject).then(res => {
                this.initPay(res)
            })
        },
        // 继续发起支付
        reConfirmOrderScheme() {
            schemeOrder.reConfirmOrderScheme(this.payObject).then(res => {
                this.initPay(res)
            }).catch(err => {
                console.log(err)
            })
        },
        // 验证码倒计时
        getCodeCount() {
            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                    this.count--;
                } else {
                    this.showGetCode = true;
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        // 获取验证码
        accSetupSendCode() {
            this.payObject.uuid = this.$oucy.getUUID()
            financial.accSetupSendCode(this.payObject).then(res => {
                this.showGetCode = false

                this.getCodeCount()
                me.$notify({
                    title: '短信发送成功',
                    message: '验证码已发送，请留意短信通知',
                    type: 'success'
                });
            }).catch(err => {
                console.log(err)
            })
        },
        cancel() {
            if (this.payObject.type == 'enterpriseUpgrade') {
                this.markPayCancel()
            } else
            if (this.payObject.type == 'createMemberCardOrder') {
                this.markOrderCancel()
            }
        },
        // 标记会员卡取消
        markOrderCancel() {
            financial.markOrderCancel(this.payObject).then(res => {
                this.$oucy.back()
            })
        },
        // 标记店铺升级订单取消
        markPayCancel() {
            console.log(this.payObject)
            financial.markPayCancel(this.payObject).then(res => {
                this.$oucy.back()
            })
        },
    }
}