<template>
    <div class="pay m-t-40">
        <div class=" container">
            <div class="pageCenter p-10 p-t-60">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <h3>支付中心</h3>

                        <div class="content text-left p-t-90">
                            <div v-show="showType=='select'">
                                <div class="m-r-10 bold f14 text-left m-b-30">选择支付方式：</div>
                                <div class="f12 c-2 m-b-10" v-if="balance==0">当前余额不足</div>
                                <span class="btn m-r-10" :class="{'select':mode==4}" @click="setMode(4)" v-if="balance>=fee && type!='createRechargeOrder'">
                                    <img src="@/assets/images/stockCar/balance.png" class="btnImg">
                                    余额({{balance}})支付
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                                <span class="btn m-r-10" :class="{'select':mode==0}" @click="setMode(0)">
                                    <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                                    微信
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                                <span class="btn m-r-10" :class="{'select':mode==1}" @click="setMode(1)">
                                    <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                                    支付宝
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                                <span class="btn m-r-10" :class="{'select':mode==5}" @click="setMode(5)" v-if="type!='createRechargeOrder' && balance >0 && balance<fee">
                                    <img src="@/assets/images/stockCar/balance.png" class="btnImg">
                                    余额({{balance}})+                                    
                                    <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                                    微信
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                                <span class="btn m-r-10" :class="{'select':mode==6}" @click="setMode(6)" v-if="type!='createRechargeOrder' && balance >0 && balance<fee">
                                    <img src="@/assets/images/stockCar/balance.png" class="btnImg">
                                    余额({{balance}})+
                                    <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                                    支付宝
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                            </div>
                            <div class="m-t-30">
                                <el-button type="primary" size="mini" v-if="showType=='select'" @click="setShowType('pay')">选择完成去支付</el-button>                               
                            </div>
                            <div class="text-center " style="margin-top:50px" v-show="showType=='pay'">
                                <div>
                                    <div class="df df-ai-c" v-if="mode!=4">
                                        <div>
                                            <div ref="myLoginQrCodeUrl" class="payQRCode"></div>
                                        </div>
                                        <div class="text-left m-l-30">
                                            <div class="c-1 f16">本次交易需支付:</div>
                                            <div class="price f24 m-t-15">￥{{payFee}}</div>
                                            <div class="c-1 f16 m-t-20">剩余支付时间 <span class="time" style="color: red;font-size: 22px">
                                                    <countDown ref="countDown" @stopTime="stopTime" @updateTime="updateTime" :s='payExpSeconds'></countDown>
                                                </span>超时订单自动关闭</div>
                                            <!-- <div class="c-1 f12 m-t-20">剩余支付时间 <span class="time">00:15:48</span>超时订单自动关闭</div> -->
                                        </div>
                                    </div>
                                    <div class="df df-ai-c" v-else>
                                        <div class="text-left m-l-0">
                                            <div class="c-1 f16"><span class="bold">本次交易需支付</span><span>(当前余额</span><span  class="c-m">￥{{balance}}</span>):</div>
                                            <div class="price f24 m-t-15">￥{{fee}}</div>
                                            <div class="c-1 f16 m-t-20" v-if="0">剩余支付时间 <span class="time" style="color: red;font-size: 22px">
                                                    <countDown ref="countDown" @stopTime="stopTime" @updateTime="updateTime" :s='payExpSeconds'></countDown>
                                                </span>超时订单自动关闭</div>
                             
                                            <div>
                                                <div class="bold m-t-30"><span v-if="loginUser && loginUser.userPhoneNumber" class="c-2 m-l-10">请输入手机号{{loginUser.userPhoneNumber.substring(0, 3) + "****" + loginUser.userPhoneNumber.substring(7, 11)}}收到的手机短信验证码！</span></div>
                                                <div>
                                                    <el-input v-model="phoneCode" class="m-t-20" autocomplete="off" placeholder="请输入短信验证码">
                                                        <el-button @click="accSetupSendCode" :disabled="!showGetCode" type="text" style="padding-right:10px;color:#0366C3;" slot="suffix">
                                                            <span v-if="showGetCode">获取验证码</span>
                                                            <span v-else class="count">{{count}} s</span>
                                                        </el-button>
                                                    </el-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top:86px;text-align:left;">
                                        <!-- <el-button type="primary" size="mini" v-if="showType=='pay'" @click="setShowType('select')">回去重新选择</el-button> -->
                                        <el-button type="primary" size="mini" @click="createOrder" v-if="mode==4">立即支付</el-button>
                                        <el-button size="mini" @click="$oucy.back()">返回</el-button>
                                        <el-button type="primary" size="mini" v-if="(payObject.type=='enterpriseUpgrade' || payObject.type=='createMemberCardOrder') && mode!=4" @click="cancel">订单取消</el-button>
                                    </div>
                                </div>
                                <div v-if="0" class="duringPayment"><img src="@/assets/images/stockCar/await.gif" class="awaitGif">扫码成功，正在支付...</div>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import pay from './pay.js'
export default pay
</script>
<style lang="less" scoped>
@import "./pay.less";
</style>